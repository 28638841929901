import client from '@/plugins/axios';

export const ai = {
  async generateAI(prompt) {
    return client.post('/get_image', {
      size: 1,
      prompt,
      steps: 20,
      gScale: 10,
    });
  },

  async generateCompanyLabels(prompt, lang) {
    return client.post('/ai', {
      prompt,
      lang,
    });
  },

  async generateFace(fields) {
    return client.post('/form-controller', fields);
  },

  async generatePhoto(fields) {
    return client.post('form-controller/second-step-generate', fields);
  },

  async generatePhotoByImg(fields) {
    return client.post('form-controller/second-step-upload', fields);
  },

  async saveFace(fields) {
    return client.post('form-controller/save-face', fields);
  },

  async getFormFields(id) {
    return client.get(`form-controller/get/${id}`);
  },

  async getAiModels() {
    return client.get('form-controller/list');
  },

  async changeModelName({ id, ...fields }) {
    return client.post(`form-controller/update/${id}`, fields);
  },

  async deleteModel(id) {
    return client.delete(`form-controller/delete/${id}`);
  },
};
